<template>
	<div class="ac--container">
		<div class="ac--content">
			<LUISidebar v-show="show"></LUISidebar>
			<div class="ac--content--views">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import LUISidebar from "../components/LUISidebar"

export default {
	name: "AppContainer",
	components: {
		LUISidebar
	},
	computed: {
		show: function () {
			if (this.$route.name.includes("cases")) {
				return false
			} else {
				return true
			}
		}
	}
}
</script>

<style scoped>
.ac--container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.ac--content {
	display: flex;
	height: 100%;
}

.ac--content--views {
	height: 100%;
	overflow-y: scroll;
	flex-grow: 1;
	background: rgba(243, 243, 244);
}

::-webkit-scrollbar {
	width: 0em;
}
</style>
