import Vue from "vue"
import App from "./App"
import VueHighlightJS from "vue-highlightjs"

import router from "./router"
import store from "./store"

Vue.use(VueHighlightJS)

new Vue({
	router,
	store,
	el: "#app",
	render: h => h(App)
})
