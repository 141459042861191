<template>
	<div class="sddv--container">
		<LUITable
			v-if="isTable"
			headline="Places"
			:labels="tableHeadlines"
			:device="getSingleDevicePlaces"
			:entries="getSingleDevicePlacesArray"
			:content-keys="tableContentKeys"
			:clickable="false"
		>
		</LUITable>
	</div>
</template>

<script>
import LUITable from "../components/LUITable"
import { mapGetters } from "vuex"

export default {
	name: "SingleDeviceDataView",
	components: {
		LUITable
	},
	data () {
		return {
			isTable: true,
			tableHeadlines: [
				"Distance",
				"Name",
				"Adress",
				"Category",
			],
			tableContentKeys: [
				"distance",
				"name",
				"adress",
				"category"
			]
		}
	},
	computed: {
		...mapGetters([
			"getSingleDevicePlaces",
			"getSingleDevicePlacesArray",
			"getDeviceDataCollection"
		])
	},
	beforeUpdate () {
		const firstElementOfTable = document.querySelector("#table--device--meta")
		firstElementOfTable.scrollIntoView()
	},
	methods: {
		showTable: function () {
			this.isTable = true
		},
		showMap: function () {
			this.isTable = false
		}
	}
}
</script>

<style scoped>
</style>
