import Vue from "vue"
import Vuex from "vuex"

import deviceDataCollection from "../statics/json/advertisement_api-devices_with_places.json"

Vue.use(Vuex)

let defaultState = {
	isEventHandlerDisabled: true,
	applicationIsLoading: false,
	currentFilteredCollection: deviceDataCollection.collection,
	deviceDataCollection: deviceDataCollection.collection,
	deviceRequestCollection: "",
	deviceResponseCollection: [],
	buildingRequestCollection: "",
	buildingResponseCollection: "",
	searchRadius: 100,
	poiCount: 50,
	singleDevicePlacesArray: {},
	singleDeviceBuildingsArray: {},
	currentSelectedOptionPlaces: [],
	currentSelectedOptionPlacesName: [],
	currentCollectionCenterLocation: {
		lat: 40.73,
		long: -73.99
	},
	//mapZoom: 18,
	apiExplorer: {
		requestActive: true,
		responseActive: false
	},
	isClickable: false,
	isOverlayVisible: false,
	map: {},
	mapContentType: "pois",
	mapMarkerCollection: [],
	mapDrawRadius: false,
	random: 0
}

const store = new Vuex.Store({
	state: defaultState,
	getters: {
		//getMapZoom: state => {
		//	//return state.mapZoom
		//	return 18
		//},
		getMapDrawRadius: state => {
			return state.mapDrawRadius
		},
		getRandom: state => {
			return state.random
		},
		getMapMarkerCollection: state => {
			return state.mapMarkerCollection
		},
		getMap: state => {
			return state.map
		},
		getIsOverlayVisible: state => {
			return state.isOverlayVisible
		},
		getIsClickable: state => {
			return state.isClickable
		},
		getDeviceRequestCollection: state => {
			return state.deviceRequestCollection
		},
		getDeviceResponseCollection: state => {
			return state.deviceResponseCollection
		},
		getBuildingRequestCollection: state => {
			return state.buildingRequestCollection
		},
		getBuildingResponseCollection: state => {
			return state.buildingResponseCollection
		},
		getCurrentCollectionCenterLocation: state => {
			return state.currentCollectionCenterLocation
		},
		getCurrentSearchRadius: state => {
			return state.searchRadius
		},
		getCurrentPoiCount: state => {
			return state.poiCount
		},
		getCurrentFilteredCollection: state => {
			return state.currentFilteredCollection
		},
		getDeviceDataCollection: state => {
			return state.deviceDataCollection
		},
		getSingleDevicePlaces: state => {
			return state.singleDevicePlacesArray
		},
		getSingleDevicePlacesArray: state => {
			return state.singleDevicePlacesArray.places
		},
		getSingleDeviceBuildings: state => {
			return state.singleDeviceBuildingsArray
		},
		getSingleDeviceBuildingsArray: state => {
			// This was part of showing the POIS in the given radius if device
			// was not in a building - wrong
			//
			//
			// const buildingsArray = state.singleDeviceBuildingsArray.buildings

			// if (buildingsArray !== undefined && buildingsArray.length === 0) {
			// 	return state.singleDeviceBuildingsArray.places
			// } else {
			// 	return state.singleDeviceBuildingsArray.buildings
			// }

			return state.singleDeviceBuildingsArray.buildings
		},
		getApiExplorerRequestState: state => {
			return state.apiExplorer.requestActive
		},
		getApiExplorerResponseState: state => {
			return state.apiExplorer.responseActive
		},
		getApplicationIsLoading: state => {
			return state.applicationIsLoading
		},
		isEventHandlerDisabled: state => {
			return state.isEventHandlerDisabled
		}
	},
	mutations: {
		setEventHandlerDisabled (state) {
			state.isEventHandlerDisabled = true
		},
		setEventHandlerEnabled (state) {
			state.isEventHandlerDisabled = false
		},
		setRandom (state) {
			state.random = Math.random()
		},
		//setMapZoom (state, value) {
		//	state.mapZoom = value
		//},
		setApplicationIsLoading (state, value) {
			state.applicationIsLoading = value
		},
		setMapMarkerCollection (state, collection) {
			state.mapMarkerCollection = collection
		},
		setMap (state, map) {
			state.map = map
		},
		setBuildingResponseCollection (state) {
			state.buildingResponseCollection = deviceDataCollection.buildingResponseQuery.filter((item, index) => index < 7)
		},
		resetBuildingResponseCollection (state) {
			state.buildingResponseCollection = ""
		},
		setDeviceResponseCollection (state) {
			state.deviceResponseCollection = deviceDataCollection.responseQuery.filter((item, index) => index < 7)

			// TODO beginning of filtering results depending on filter settings
			// delayed because of other TODOs which have higher prio atm
			//
			//let collection = []
			//deviceDataCollection.responseQuery.forEach((item, index) => {
			//	//console.log(index)
			//	//console.log(item)
			//	const results = item.data[0].results
			//	let filteredResults = []
			//	const radius = state.searchRadius
			//	const count = state.poiCount

			//	for (const index in results) {
			//		const item = results[index]
			//		if (index <= count && item.calc_distance <= radius) {
			//			filteredResults.push(item)
			//		}
			//	}

			//	item.data[0].results = filteredResults

			//	collection.push(item)
			//})

			//console.log(collection)

			//state.deviceResponseCollection = collection
		},
		resetDeviceResponseCollection (state) {
			state.deviceResponseCollection = ""
		},
		setDeviceRequestCollection (state) {
			const template = {
				"output_geo_properties": [
					"d6c4eec2-272c-4dc0-8dbc-b4b32086e40d",
					"d4b377e9-15dd-4d80-9006-4d809ddfc577",
					"842baf42-2219-4170-a29f-5794ecb57463",
					"b6b06929-e117-4343-a6de-339acd2cef85",
					"365a9433-ec74-4ba1-829e-c3dfc283791a",
					"0f51fa4b-bddf-4b44-8fbc-2159b412b418"
				],
				filter_geo_properties: [
					{
						geo_property_id: "d6c4eec2-272c-4dc0-8dbc-b4b32086e40d",
						property_value: []
					}
				],
				data: []
			}

			for (const item of state.currentSelectedOptionPlaces) {
				template.filter_geo_properties[0].property_value.push(item)
			}

			for (const item of state.currentSelectedOptionPlacesName) {
				template.filter_geo_properties[0].property_value.push(item)
			}

			for (const item of state.currentFilteredCollection) {
				const dataEntry = {
					entity_id: item.id,
					lat: item.lat,
					lon: item.long
				}

				template.data.push(dataEntry)
			}

			state.deviceRequestCollection = template
		},
		setBuildingRequestCollection (state) {
			const template = {
				"output_geo_properties": [
					"5e467099-578e-4857-9bbc-4b7fd5ef5d4a",
					"40a0ad1d-8944-455c-bd18-14fbb808f1d9",
					"b477963c-0962-405c-994f-ea8713969ef6",
					"10b8066d-ee8b-49c2-a125-c0cc1e67c758"
				],
				data: []
			}

			for (const item of state.currentFilteredCollection) {
				const dataEntry = {
					entity_id: item.id,
					lat: item.lat,
					lon: item.long
				}

				template.data.push(dataEntry)
			}

			state.buildingRequestCollection = template
		},
		showOverlay (state) {
			state.isOverlayVisible = true
		},
		hideOverlay (state) {
			state.isOverlayVisible = false
		},
		setIsClickableTrue (state) {
			state.isClickable = true
		},
		setIsClickableFalse (state) {
			state.isClickable = false
		},
		loadDataCollection (state) {
			state.deviceDataCollection = deviceDataCollection.collection
		},
		searchRadiusUpdate (state, value) {
			state.searchRadius = value
		},
		poiCountUpdate (state, value) {
			state.poiCount = value
		},
		setSingleDevicePlacesArray (state, id) {
			for (const entry of state.currentFilteredCollection) {
				if (entry.id === id) {
					const reformattedSinglePlace = {
						id: entry.id,
						lat: entry.lat,
						long: entry.long,
						placescount: entry.placescount,
						buildingscount: entry.buildingscount,
						buildings: entry.buildings,
						places: []
					}

					for (const place of entry.places) {
						const reformattedPlace = {
							distance: place.calc_distance,
							name: place.name,
							adress: `${place.road_name}\n${place.postal_code} ${place.isocc}`,
							category: place.categories_ids.replace(/\|/g, ", ").replace(/\//g, ", ")
						}

						reformattedSinglePlace.places.push(reformattedPlace)
					}

					state.singleDevicePlacesArray = reformattedSinglePlace
				}
			}
		},
		setSingleDeviceBuildingsArray (state, id) {
			for (const entry of state.currentFilteredCollection) {
				if (entry.id === id) {
					state.singleDeviceBuildingsArray = entry
				}
			}
		},
		setCurrentSelectedOptionPlaces (state, payload) {
			if (payload.dropdownType === "category") {
				state.currentSelectedOptionPlaces = payload.value
			} else {
				state.currentSelectedOptionPlacesName = payload.value
			}
		},
		setDeviceDataCollectionFiltered (state) {
			const collection = state.deviceDataCollection
			const filteredCollection = []
			const radius = state.searchRadius
			const count = state.poiCount
			const selectedCategory = state.currentSelectedOptionPlaces
			const selectedName = state.currentSelectedOptionPlacesName

			for (const item of collection) {
				const filterdPlaces = []

				for (const index in item.places) {
					const place = item.places[index]

					if (index < count
						&& place.calc_distance <= radius
						&& place.lat !== undefined
						&& place.categories_ids !== null) {

						if (selectedCategory.length > 0) {
							for (const category of selectedCategory) {
								if (place.categories_ids.includes(category)) {
									filterdPlaces.push(place)
								}
							}
						} else if (selectedName.length > 0) {
							for (const name of selectedName) {
								if (place.name.includes(name)) {
									filterdPlaces.push(place)
								}
							}
						} else {
							filterdPlaces.push(place)
						}
					}
				}

				const filteredItem = {
					id: item.id,
					placescount: filterdPlaces.length,
					buildingscount: item.buildings.length,
					lat: item.lat,
					long: item.long,
					places: filterdPlaces,
					buildings: item.buildings
				}
				
				//if (filteredItem.places.length > 0) {
				filteredCollection.push(filteredItem)
				//}
			}

			state.currentFilteredCollection = filteredCollection
		},
		setMapMarkerCollectionPlaces (state) {
			state.currentCollectionCenterLocation = {
				lat: 40.73,
				long: -73.99
			}

			state.mapDrawRadius = false
			state.mapMarkerCollection = state.currentFilteredCollection
		},
		setMapMarkerCollectionBuildings (state) {
			state.currentCollectionCenterLocation = {
				lat: 40.73,
				long: -73.99
			}

			state.mapDrawRadius = false
			state.mapMarkerCollection = state.currentFilteredCollection
		},
		setMapMarkerCollectionSinglePlace (state, value) {
			state.currentCollectionCenterLocation = {
				lat: value.lat,
				long: value.long
			}

			state.mapDrawRadius = true
			state.mapMarkerCollection = value.places
		},
		setMapMarkerCollectionSingleBuilding (state, value) {
			state.currentCollectionCenterLocation = {
				lat: value.lat,
				long: value.long
			}

			// if buildingscount is one, it will be a marker which resists in one building and therefore only this needs to be given to the marker collection
			// If the buildingscount differes, there are multiple options and therefore a marker with a radius will be shown
			if (value.buildingscount === 1) {
				state.mapDrawRadius = false
				state.mapMarkerCollection = [value]
			} else {
				state.mapDrawRadius = true
				state.mapMarkerCollection = value.places
			}

		},
		setApiExplorerRequestState (state) {
			state.apiExplorer.requestActive = true
			state.apiExplorer.responseActive = false
		},
		setApiExplorerResponseState (state) {
			state.apiExplorer.responseActive = true
			state.apiExplorer.requestActive = false
		},
		setSingleDevicePlacesArrayEmpty (state) {
			state.singleDevicePlacesArray = {}
		},
		setDefaultState (state) {
			state.isEventHandlerDisabled = true
			state.applicationIsLoading = false
			state.currentFilteredCollection = deviceDataCollection.collection
			state.deviceDataCollection = deviceDataCollection.collection
			state.singleDevicePlacesArray = {}
			state.singleDeviceBuildingsArray = {}
			state.currentCollectionCenterLocation = {
				lat: 40.73,
				long: -73.99
			}
			state.apiExplorer = {
				requestActive: true,
				responseActive: false
			}
			state.isClickable = false
			state.isOverlayVisible = false
			state.mapMarkerCollection = []
			state.mapDrawRadius = false
		}
	},
	actions: {
		resetAppState ({ commit }) {
			commit("setDefaultState")
			commit("hideOverlay")
			commit("setApiExplorerRequestState")
			commit("setMapMarkerCollectionPlaces")
			commit("searchRadiusUpdate", 100)
			commit("poiCountUpdate", 50)
			commit("resetDeviceResponseCollection")
			commit("resetBuildingResponseCollection")
			commit("setDeviceRequestCollection")
			commit("setBuildingRequestCollection")
			commit("setCurrentSelectedOptionPlaces", {dropdownType: "category", value: ""})
			commit("setCurrentSelectedOptionPlaces", {dropdownType: "name", value: ""})
		}
	}
})

export default store
