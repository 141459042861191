<template>
	<div class="pv--container">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "PlacesView"
}
</script>

<style scoped>
.pv--container {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}
</style>
