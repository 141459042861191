<template>
	<div class="sidebar--container">
		<div class="sidebar--navigation">
			<div
				class="sidebar--item"
				:class="{'sidebar--item-active': showFor('places')}"
				@click="navigateTo('/app/places')"
			>
				<div class="sidebar--item--indicator"></div>
				<div class="sidebar--item--icon icon-places not-selectable"></div>
				<div class="sidebar--item--label not-selectable">Places</div>
			</div>
			<div
				class="sidebar--item"
				:class="{'sidebar--item-active': showFor('buildings')}"
				@click="navigateTo('/app/buildings')"
			>
				<div class="sidebar--item--indicator"></div>
				<div class="sidebar--item--icon icon-buildings not-selectable"></div>
				<div class="sidebar--item--label not-selectable">Buildings</div>
			</div>
		</div>
		<div
			v-if="showFor('places')"
			class="sidebar--content"
		>
			<SidebarContentPlaces></SidebarContentPlaces>
		</div>
		<div
			v-if="showFor('buildings')"
			class="sidebar--content"
		>
			<SidebarContentBuildings></SidebarContentBuildings>
		</div>
		<div
			v-if="showFor('places')"
			class="sidebar--content"
		>
			<SidebarContentPlacesQuery></SidebarContentPlacesQuery>
		</div>
		<div
			v-if="showFor('buildings')"
			class="sidebar--content"
		>
			<SidebarContentBuildingsQuery></SidebarContentBuildingsQuery>
		</div>
	</div>
</template>

<script>
import SidebarContentPlaces from "./SidebarContentPlaces"
import SidebarContentPlacesQuery from "./SidebarContentPlacesQuery"
import SidebarContentBuildings from "./SidebarContentBuildings"
import SidebarContentBuildingsQuery from "./SidebarContentBuildingsQuery"

export default {
	name: "LuiSidebarSequential",
	components: {
		SidebarContentPlaces,
		SidebarContentPlacesQuery,
		SidebarContentBuildings,
		SidebarContentBuildingsQuery
	},
	data () {
		return {
			routes: this.$router.options.routes
		}
	},
	methods: {
		showFor: function (routeName) {
			if (this.$route.name.includes(routeName)) {
				return true
			} else {
				return false
			}
		},
		navigateTo: function (route) {
			this.$store.dispatch("resetAppState")
			if (route === "/app/places") {
				this.$store.commit("searchRadiusUpdate", 100)
			}

			if (route === "/app/buildings") {
				this.$store.commit("searchRadiusUpdate", 0)
			}

			this.$router.push(route)
		}
	}
}
</script>

<style scoped>
	.sidebar--container {
		background: #272D37;
		display: flex;
		flex-direction: row;
	}

	.sidebar--navigation {
		width: 6.25em;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	.sidebar--content {
		height: 100%;
		display: flex;
		flex-direction: column;
		width: 25em;
		border-left: 0.0625em solid rgb(71, 76, 85);
	}

	.sidebar--item,
	.sidebar--item--back {
		height: 6.25em;
		width: 6.25em;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		text-decoration: none;
		outline: none;
		opacity: 0.4;
		position: relative;
	}

	.sidebar--item--indicator {
		width: 0.1875em;
		height: 5em;
		position: absolute;
		background: rgba(45, 213, 201, 1);
		left: 0.625em;
		top: 0.625em;
		opacity: 0;
	}

	.sidebar--item-active {
		opacity: 1;
	}

	.sidebar--item-active > .sidebar--item--indicator {
		opacity: 1;
	}

	.sidebar--item--backarrow {
		width: 1.625em;
		height: 1.5em;
		background: url("../../statics/images/backarrow.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.sidebar--item--back:hover > .sidebar--item--backarrow {
		background: url("../../statics/images/backarrow-hover.svg");
	}
	
	.sidebar--item--back:active > .sidebar--item--backarrow {
		background: url("../../statics/images/backarrow-pressed.svg");
	}

	.sidebar--item--back-disabled > .sidebar--item--backarrow {
		background: url("../../statics/images/backarrow-disabled.svg");
	}

	.sidebar--item--icon {
		width: 2.5em;
		height: 2.5em;
		margin-bottom: 0.5em;
	}

	.sidebar--item--label {
		font-size: 0.8125em;
		color: rgba(255, 255, 255, 1);
	}

	.sidebar--item:hover {
		opacity: 1;
	}

	.sidebar--item:active {
		opacity: 0.1;
	}

	.icon-import {
		background: url("../../statics/images/import.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.icon-places {
		background: url("../../statics/images/places.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.icon-buildings {
		background: url("../../statics/images/building.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}
</style>
