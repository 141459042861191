<template>
	<div class="app--container">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "App",
	mounted() {
		this.$store.commit("setDeviceRequestCollection")
		this.$store.commit("setBuildingRequestCollection")
	}
}
</script>

<style>
@font-face {
	font-family: "FiraGo Regular";
	src: url("../statics/fonts/FiraGO-Regular.woff2") format("woff2")
}

@font-face {
	font-family: "FiraGo Medium";
	src: url("../statics/fonts/FiraGO-Medium.woff2") format("woff2")
}

@font-face {
	font-family: "FiraGo Bold";
	src: url("../statics/fonts/FiraGO-Bold.woff2") format("woff2")
}

html { font-size: 1em; }

@media screen and (min-width: 3000px) {
	html { font-size: 2em; }
}

::-webkit-scrollbar {
	width: 0px;
}

body {
	font-family: "FiraGo Regular", sans-serif;
	font-size: 1em;
	margin: 0;
	padding: 0;
	height: 100vh;
	width: 100vw;
}

.font--bright { color: #fff; }
.font--dark { color: #000; }

.app--container {
	width: 100%;
	height: 100%;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
