<template>
	<div class="cv--container">
		<div
			v-for="entry of entries"
			:key="entry.headline"
			class="cv--content"
		>
			<div class="cv--content--topbar">
				<h3>{{ entry.topbar }}</h3>
			</div>
			<div class="cv--content--entry">
				<h1>{{ entry.headline }}</h1>
				<p>{{ entry.text }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CasesView",
	data () {
		return {
			entries: [
				{
					topbar: "Audience Segmentation",
					headline: "Analyze historic mobility patterns of your users.",
					text: "Use HERE Advertising Data Services to translate user location signals into smart location context: nearby points-of-interests (POIs), precise building shapes and more. Analyze mobility patterns, places people have visited, shopped at, dined at, and build more precise audience segments."
				},
				{
					topbar: "Campaign attribution",
					headline: "Understand whether or not an ad led to the desired action",
					text: "Use HERE Advertising Data Services to better differentiate between location signals coming from inside stores as opposed to near stores. Understand dwell time and store visitation rates to measure the impact of your advertising campaigns and optimize future ones."
				}
			]
		}
	}
}
</script>

<style scoped>
	.cv--container {
		background: rgba(39, 45, 55, 1);
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		height: 100%;
		width: 100%;
	}

	.cv--content {
		height: calc(100% - 4em);
		width: calc(50% - 3em);
		background: rgba(255, 255, 255, 1);
		display: flex;
		flex-direction: column;
	}

	.cv--content:nth-child(1) {
		margin-left: 1em;
	}

	.cv--content:nth-child(2) {
		margin-right: 1em;
	}

	.cv--content--topbar {
		width: calc(100% - 4em);
		height: 4.25em;
		background: rgba(243, 243, 244, 1);
		padding: 0 2em;
		display: flex;
		align-items: center;
	}

	.cv--content--topbar h3 {
		font-size: 1.75em;
		font-family: "FiraGo Medium", sans-serif;
		color: rgba(61, 67, 75, 1);
		margin: 0;
		width: 70%;
	}

	.cv--content--entry {
		padding: 1.625em 2em;
		width: 70%;
	}

	.cv--content--entry h1 {
		color: rgba(15, 22, 33, 0.8);
		font-family: "FiraGo Medium", sans-serif;
		font-size: 2.5em;
		margin: 0;
	}

	.cv--content--entry p {
		color: rgba(15, 22, 33, 0.8);
		font-family: "FiraGo Regular", sans-serif;
		font-size: 1em;
	}
</style>
