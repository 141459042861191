<template>
	<div class="sc--places--container">
		<div class="sc--places--label">API Layer</div>
		<div class="sc--places--headline">HERE Places</div>
		<div class="sc--places--description">
			Query the Places layer of the API to get the nearest points of interest (POIs) around your geo-coordinates.
		</div>
		<div class="sc--places--slider">
			<LUISlider
				label="Search Radius"
				value-type="searchRadius"
				max="100"
				unit="m"
			>
			</LUISlider>
		</div>
		<div class="sc--places--slider">
			<LUISlider
				label="POI count"
				value-type="poiCount"
				max="50"
			>
			</LUISlider>
		</div>
		<div class="sc--places--selection">
			<LUIDropdown
				label="Select Category"
				type="category"
				:options="categories"
			>
			</LUIDropdown>
		</div>
		<LUIDropdown
			label="Select Name"
			type="name"
			:options="names"
		>
		</LUIDropdown>
		<div class="sc--places--selection">
		</div>
		<div class="sc--places--button">
			<LUIButton
				:click-event="emulatePlacesQuery"
				membrane="context"
				button-label="Send Query"
				:disabled="getApplicationIsLoading"
				size="medium"
				:primary="true"
				:is-loading="getApplicationIsLoading"
			></LUIButton>
		</div>
	</div>
</template>

<script>
import LUIButton from "./LUIButton"
import LUISlider from "./LUISlider"
import LUIDropdown from "./LUIDropdown"
import { mapGetters } from "vuex"
import categories from "../../statics/json/categories.js"
import names from "../../statics/json/names.js"

export default {
	name: "SidebarContentPlaces",
	components: {
		LUIButton,
		LUISlider,
		LUIDropdown
	},
	data () {
		return {
			isLoading: false,
			categories: categories,
			names: names
		}
	},
	computed: {
		...mapGetters(["getApplicationIsLoading"])
	},
	methods: {
		emulatePlacesQuery: function () {
			clearTimeout(emulatedLoadingTimeout)

			this.$router.push("/app/places")
			this.$store.commit("setApplicationIsLoading", true)
			this.$store.commit("setDeviceResponseCollection")
			this.$store.commit("setIsClickableFalse")
			this.$store.commit("setEventHandlerEnabled")
			const emulatedLoadingTimeout = setTimeout(() => {
				this.$store.commit("hideOverlay")
				this.$store.commit("setDeviceDataCollectionFiltered")
				this.$store.commit("setDeviceRequestCollection")
				this.$store.commit("setIsClickableTrue")
				this.$store.commit("setApiExplorerResponseState")
				this.$store.commit("setMapMarkerCollectionPlaces")
				//this.$store.commit("setMapZoom", 16)
				this.$store.commit("setApplicationIsLoading", false)
			}, 2000)
		}
	}
}
</script>

<style scoped>
.sc--places--container {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 1.25em;
}

.sc--places--label {
	color: rgba(255, 255, 255, 0.6);
	text-decoration: underline;
	margin-bottom: 1.25em;
	font-size: 0.8125em;
}

.sc--places--headline {
	color: rgba(255, 255, 255, 1);
	margin-bottom: 0.625em;
	font-size: 2em;
	font-weight: bold;
}

.sc--places--subheadline {
	color: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
	font-family: "FiraGo Medium", sans-serif;
	font-size: 0.8125em;
	margin-bottom: 1.75em;
}

.sc--places--description {
	color: rgba(255, 255, 255, 0.8);
	font-size: 1em;
	line-height: 1.625em;
	margin-bottom: 4em;
}

.sc--places--button {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.sc--places--selection {
	margin-top: 1.5em;
	margin-bottom: 1.25em;
}
</style>
