<template>
	<div class="brandmembrane--container">
		<div class="brandmembrane--logo"></div>
		<div class="brandmembrane--headline font--bright">{{ headlineText }}</div>
		<LUIButton
			navigation-path="/app/places"
			:click-event="navigateToAppContainer"
			membrane="aqua"
			:button-label="buttonLabel"
			size="medium"
		>
		</LUIButton>
	</div>
</template>

<script>
import LUIButton from "../components/LUIButton.vue"
export default {
	name: "BrandMembrane",
	components: {
		LUIButton
	},
	data () {
		return {
			headlineText: "HERE Advertising Data Services Demo",
			buttonLabel: "Start"
		}
	},
	methods: {
		navigateToAppContainer: function () {
			this.$router.push("/app")
		}
	}
}
</script>

<style scoped>
.brandmembrane--container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: url("../../statics/images/brandmembrane-background.svg");
	background-size: cover;
}

.brandmembrane--logo {
	width: 3.5em;
	height: 3.25em;
	background: url("../../statics/images/brandmembrane-logo.png");
	background-size: contain;
	margin-bottom: 3em;
}

.brandmembrane--headline {
	font-size: 2.25em;
	margin-bottom: 1.5em;
}
</style>
