import Vue from "vue"
import VueRouter from "vue-router"

import BrandMembrane from "./views/BrandMembrane"
import AppContainer from "./views/AppContainer"
import PlacesView from "./views/PlacesView"
import BuildingsView from "./views/BuildingsView"
import BuildingsDataView from "./views/BuildingsDataView"
import PlacesDataView from "./views/PlacesDataView"
import SingleDeviceDataView from "./views/SingleDeviceDataView"
import SingleBuildingDataView from "./views/SingleBuildingDataView"
import CasesView from "./views/CasesView"

Vue.use(VueRouter)

const routes = [
	{ path: "/", name: "BrandMembrane", component: BrandMembrane },
	{
		path: "/app",
		redirect: "/app/places",
		name: "AppContainer",
		component: AppContainer,
		children: [
			{
				path: "places",
				component: PlacesView,
				children: [
					{
						name: "places",
						path: "/",
						component: PlacesDataView,
						children: [
							{
								name: "placessingle",
								path: "singledevice/:id",
								component: SingleDeviceDataView
							}
						]
					}
				]
			},
			{
				path: "buildings",
				component: BuildingsView,
				children: [
					{
						name: "buildings",
						path: "/",
						component: BuildingsDataView,
						children: [
							{
								name: "buildingssingle",
								path: "singlebuilding/:id",
								component: SingleBuildingDataView
							}
						]
					}
				]
			},
			{
				path: "cases",
				name: "cases",
				component: CasesView
			}
		]
	}
]

const router = new VueRouter({ routes })

export default router
