<template>
	<div class="dropdown--container">
		<div class="dropdown--label">{{ label }}</div>
		<div class="dropdown--select">
			<Multiselect
				v-model="currentSelectedOption"
				:options="options"
				:searchable="isSearchable"
				:multiple="true"
			>
			</Multiselect>
		</div>
	</div>
</template>

<script>
import Multiselect from "../lib/Multiselect"
export default {
	name: "LUIDropdown",
	components: {
		Multiselect
	},
	props: {
		label: {
			type: String,
			default: ""
		},
		options: {
			type: Array,
			default() {
				return []
			}
		},
		type: {
			type: String,
			default: ""
		}
	},
	computed: {
		isSearchable: function () {
			if (this.type === "category") {
				return false
			} else {
				return true
			}
		},
		currentSelectedOption: {
			get () {
				if (this.type === "category") {
					return this.$store.state.currentSelectedOptionPlaces
				} else {
					return this.$store.state.currentSelectedOptionPlacesName
				}
			},
			set (value) {
				this.$store.commit("setCurrentSelectedOptionPlaces", {
					value: value,
					dropdownType: this.type
				})
				this.$store.commit("setDeviceRequestCollection")
			}
		}
	}
}
</script>

<style scoped>
.dropdown--container {
	display: flex;
	flex-direction: column;
}

.dropdown--label {
	font-family: "FiraGo Medium", sans-serif;
	font-size: 0.8125em;
	color: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
}

.dropdown--select {
	width: 100%;
	position: relative;
	padding-top: 0.625em;
}

.dropdown--select--arrow {
	position: absolute;
	top: 1.125em;
	right: 0.625em;
	display: none;
}

.dropdown--select select {
	width: 100%;
	padding: 0.625em;
	background: rgba(15, 22, 33, 0.3);
	border: 0.0625em solid rgba(15, 22, 33, 0.4);
	color: rgba(255, 255, 255, 0.8);
	font-family: "FiraGo Regular", sans-serif;
	font-size: 1em;
	line-height: 1.25em;
	appearance: none; 
	-webkit-appearance: none;
	-moz-appearance: none; 
}

option {
	margin: 0.5em 0;
}

</style>
