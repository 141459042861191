<template>
	<div class="table--container">
		<div class="table--scroll">
			<table class="table">
				<tr class="table--head">
					<th
						v-for="(label, index) in labels"
						:key="index"
						class="table--item table--head--item"
						@click="sortTable(label.key)"
					>
						<span>
							{{ label.value }}
						</span>
						<div
							v-show="sortKey === label.key"
							class="sort--arrow"
							:class="{ 'sort--arrow-reverse': sortOrders[sortKey] === 1 }"
						>
							<svg
								width="10"
								height="6"
								viewBox="0 0 10 6"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M5.2825 5.84252L3.98125 4.62628L1.10864 1.92264C1.02825 1.84731 0.987518 1.76697 0.987518 1.6816C0.987518 1.59724 1.02825 1.51689 1.10864 1.44157L1.87824 0.705397C1.95006 0.629068 2.03795 0.590904 2.14299 0.590904C2.24804 0.590904 2.33593 0.629068 2.40667 0.704392L5.28143 3.37891L8.14976 0.701379C8.2205 0.625051 8.30196 0.587891 8.39736 0.587891C8.49275 0.587891 8.57636 0.625051 8.64818 0.701379L9.44886 1.43655C9.6 1.59623 9.6 1.75893 9.44993 1.91762L6.58054 4.62427L5.2825 5.84252Z"
									fill="#666A71"
									fill-opacity="0.8"
								></path>
							</svg>
						</div>
					</th>
				</tr>
				<span
					v-for="(entry, index) in sortedData"
					:key="index"
					@click="setSingleContent(entry)"
				>
					<router-link
						v-if="clickable"
						:key="entry.id"
						tag="tr"
						:to="`${path}${entry.id}`"
						class="table--row"
						:class="{
							'table--pointer': clickable,
							'table--row--even': index % 2,
							'table--row--odd': !(index % 2)
						}"
						active-class="table--row-active"
					>
						<td
							v-for="(key, subindex) in contentKeys"
							:key="subindex"
							class="table--item table--row--item"
						>
							<span v-if="key !== 'placescount' && key !== 'buildingscount'">
								{{ entry[key] }}
							</span>
							<FlagPlaces
								v-if="flagType === 'places' && key === 'placescount' && clickable"
								:count="entry.placescount"
							></FlagPlaces>
							<FlagBuildings
								v-if="flagType === 'buildings' && key === 'buildingscount' && clickable"
								:count="entry.buildingscount"
							></FlagBuildings>
							<div class="table--item--indicator"></div>
						</td>
					</router-link>
				</span>
				<tr
					v-for="(entry, index) in sortedData"
					v-if="!clickable"
					:key="entry.id"
					class="table--row"
					:class="{
						'table--pointer': clickable,
						'table--row--even': index % 2,
						'table--row--odd': !(index % 2)
					}"
				>
					<td
						v-for="(key, contentindex) in contentKeys"
						:key="contentindex"
						class="table--item table--row--item"
					>
						<span v-if="key !== 'placescount' && key !== 'buildingscount'">
							{{ entry[key] }}
						</span>
					</td>
				</tr>
			</table>
		</div>
		<div
			v-show="getIsOverlayVisible"
			class="table--border"
		>
			<div class="table--overlay">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import FlagPlaces from "./FlagPlaces"
import FlagBuildings from "./FlagBuildings"
import { mapGetters } from "vuex"

export default {
	name: "Table",
	components: {
		FlagPlaces,
		FlagBuildings
	},
	props: {
		labels: {
			type: Array,
			default() {
				return []
			}
		},
		entries: {
			type: Array,
			default() {
				return []
			}
		},
		clickable: {
			type: Boolean
		},
		contentKeys: {
			type: Array,
			default() {
				return []
			}
		},
		isCollapsed: {
			type: Boolean,
			default: true
		},
		flagType: {
			type: String,
			default: ""
		}
	},
	data () {
		let initialSortOrders = {}

		for (const label of this.labels) {
			initialSortOrders[label.key] = 1
		}
			
		return {
			sortKey: false,
			sortOrders: initialSortOrders
		}
	},
	computed: {
		path: function () {
			if (this.flagType === "places") {
				return "/app/places/singledevice/"
			} else {
				return "/app/buildings/singlebuilding/"
			}
		},
		...mapGetters([
			"getIsOverlayVisible"
		]),
		sortedData: function () {
			let entries = this.entries
			const order = this.sortOrders[this.sortKey]
			const sortKey = this.sortKey

			if (sortKey) {
				entries = entries.slice().sort(function (a, b) {
					a = a[sortKey]
					b = b[sortKey]
						
					return (a === b ? 0 : a > b ? 1 : -1) * order
				})
			}

			return entries
		}
	},
	methods: {
		sortTable: function (key) {
			this.sortKey = key
			this.sortOrders[key] = this.sortOrders[key] * -1
		},
		setSingleContent: function (entry) {
			if (this.$route.name.includes("buildings") && entry.buildingscount === 0) {
				// SUPER dirty (sorry) fix to beguile that no click event was fired
				this.$router.push("/app/buildings")
			} else if (this.$route.name.includes("places") && entry.placescount === 0) {
				// SUPER dirty (sorry) fix to beguile that no click event was fired
				this.$router.push("/app/places")
			} else {
				if (this.getIsOverlayVisible === false) {
					this.$store.commit("showOverlay")
				}

				if (this.flagType === "places") {
					this.$store.commit("setSingleDevicePlacesArray", entry.id)
					this.$store.commit("setMapMarkerCollectionSinglePlace", entry)
				}

				if (this.flagType === "buildings") {
					this.$store.commit("setSingleDeviceBuildingsArray", entry.id)
					this.$store.commit("setMapMarkerCollectionSingleBuilding", entry)
				}
			}
		}
	}
}
</script>

<style scoped>
.table--container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
	position: relative;
}

.table--scroll {
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%;
}

.table {
	font-family: "Fira Sans", sans-serif;
	width: 100%;
	border-spacing: 0.0625em;
}

.table--border {
	padding: 0 0.75em;
	margin-left: 0.875rem;
	width: 69%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	background: rgba(255, 255, 255, 1);
}

.table--overlay {
	height: calc(100% - 1.5em);
	width: calc(100% - 0.75em);
	background: rgba(255, 255, 255, 1);
	overflow-x: hidden;
	overflow-y: scroll;
	border: 0.125em dashed rgba(151, 151, 151, 0.5);
	padding: 0.75rem;
}

.table--head { background: rgba(243, 243, 244, 1); }

.table--item {
	padding: 0.75rem 0 1rem 1.25rem;
	position: relative;
}

.table--item span {
	margin-right: 1.25em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table--head--item {
	font-size: 0.8125em;
	font-family: "FiraGo Medium", sans-serif;
	line-height: 1.25em;
	height: calc(2.5em - 1.75em);
	text-transform: uppercase;	
	color: #666A71;
	display: flex;
	align-content: center;
	cursor: pointer;
}

.table--head--item,
.table--row--item {
	align-items: center;
}

.table--head, .table--row {
	display: flex;
	flex-direction: row;
}

.table--row--odd { background: rgba(248, 248, 248, 1); }
.table--row--even { background: rgba(243, 243, 244, 1); }

.table--row--item {
	font-size: 1em;
	line-height: 1.25em;
	height: calc(3.75em - 1.75em);
	color: #3A4049;
	display: flex;
	flex-wrap: nowrap;
}

.table--row-active {
	background: rgba(255, 255, 255, 1);
}

.table--item--indicator {
	width: 0.1875em;
	height: 2.5em;
	position: absolute;
	background: rgba(45, 213, 201, 1);
	right: 0em;
	top: 0.625em;
	opacity: 0;
	margin-left: 0.5em;
}

.table--row-active .table--item:nth-of-type(2) .table--item--indicator {
	opacity: 1;
}

.table--pointer {
	cursor: pointer;
}

.sort--arrow-reverse {
	transform: rotate(180deg);
}

th {
	display: flex;
	justify-content: space-between;
}

th:nth-child(1),
td:nth-child(1) {
	width: 8%;
}

td:nth-child(1) { justify-content: flex-start; }

th:nth-child(2),
td:nth-child(2) {
	width: 14%;
}

th:nth-child(3),
td:nth-child(3) {
	width: 12.5em;
	flex-grow: 1;
}

th:nth-child(4),
td:nth-child(4) {
	width: 12.5em;
	flex-grow: 1;
}
</style>
