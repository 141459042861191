<template>
	<div class="table--container">
		<div class="table--scroll">
			<div
				id="table--device--meta"
				class="table--device--meta"
			>
				<div class="table--device--meta-firstrow">
					<h2>{{ headline }}</h2>
					<LUIButton
						:click-event="closeOverlay"
						:primary="false"
						size="medium"
						button-label="close"
						membrane="content"
					></LUIButton>
				</div>
				<div>lat: {{ device.lat }}</div>
				<div>long: {{ device.long }}</div>
			</div>
			<table class="table">
				<tr
					id="table--head"
					class="table--head"
				>
					<th
						v-for="(label, index) in labels"
						:key="index"
						class="table--item table--head--item"
					>
						<span>
							{{ label }}
						</span>
					</th>
				</tr>
				<tr
					v-for="(entry, index) in entries"
					:key="entry.id"
					class="table--row"
					:class="{
						'table--pointer': clickable,
						'table--row--even': index % 2,
						'table--row--odd': !(index % 2)
					}"
				>
					<td
						v-for="(key, contentindex) in contentKeys"
						:key="contentindex"
						class="table--item table--row--item"
					>
						<span
							v-if="contentindex === 0 && entries.length === 1"
						>
							{{ (entry[key] === null) ? "" : entry[key] }}
						</span>
						<span
							v-else-if="contentindex === 1 && entries.length === 1 && !isNaN(entry[key])"
						>
							{{ parseFloat(Math.round(entry[key] * 100) / 100).toFixed(2) + "m" }}
						</span>
						<span
							v-else-if="contentindex === 0"
						>
							{{ parseFloat(Math.round(entry[key] * 100) / 100).toFixed(2) + "m" }}
						</span>
						<span v-else>
							{{ entry[key] }}
						</span>
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
import LUIButton from "./LUIButton"
import { mapGetters } from "vuex"

export default {
	name: "LUITable",
	components: {
		LUIButton
	},
	props: {
		labels: {
			type: Array,
			default () {
				return []
			}
		},
		entries: {
			type: Array,
			default () {
				return []
			}
		},
		clickable: {
			type: Boolean
		},
		contentKeys: {
			type: Array,
			default () {
				return []
			}
		},
		isCollapsed: {
			type: Boolean
		},
		device: {
			type: Object,
			default () {
				return {}
			}
		},
		headline: {
			type: String,
			default: ""
		}
	},
	computed: {
		...mapGetters([
			"getIsOverlayVisible"
		])
	},
	methods: {
		closeOverlay: function () {
			const { path } = this.$route

			//TODO dirty recognition of current parent route of router child view
			//could need cleaner refactor
			if (path.includes("buildings")) {
				this.$router.push("/app/buildings")
			} else {
				this.$router.push("/app/places")
			}
		},
		inRadius: function (entry) {
			const entryDistance = entry.calc_distance
			if (entryDistance !== undefined) {
				const currentRadius = this.$store.state.searchRadius
				if (currentRadius >= entryDistance) {
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		},
		convertArrayToButton: function (value) {
			let valueToReturn = value

			if (Array.isArray(value)) {
				valueToReturn = "Button"
			}

			return valueToReturn
		}
	}
}
</script>

<style scoped>
.table--container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
	position: relative;
}

.table--scroll {
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%;
}

.table--device--meta {
	color: #3A4049;
	display: flex;
	flex-direction: column;
	padding-top: 1.25em;
}

.table--device--meta-firstrow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.table--device--meta-firstrow button {
	border: none;
	color: rgba(0, 0, 0, 1);
	background: rgba(255, 255, 255, 1);
	align-self: flex-end;
	width: 4em;
}

.table--device--meta-firstrow h2 {
	font-family: "FiraGo Bold", sans-serif;
	font-size: 1.5rem;
	line-height: 2rem;
	margin: 0;
}

.table--device--meta div {
	font-family: "FiraGo Regular", sans-serif;
	font-size: 0.625em;
	text-transform: uppercase;
}

.table {
	font-family: "Fira Sans", sans-serif;
	width: 100%;
	border-spacing: 0.0625em;
	margin-top: 0.625em;
}

.table--overlay {
	height: 100%;
	width: 67%;
	background: rgba(255, 255, 255, 1);
	overflow-x: hidden;
	overflow-y: scroll;
	position: absolute;
	top: 0;
	right: 0;
}

.table--head { background: rgba(243, 243, 244, 1); }

.table--item {
	padding: 0.75rem 0 1rem 1.25rem;
	white-space: pre-line;
}

.table--item span {
	overflow: hidden;
}

.table--head--item {
	font-size: 0.8125em;
	font-family: "FiraGo Medium", sans-serif;
	line-height: 1.25em;
	height: calc(2.5em - 1.75em);
	text-transform: uppercase;	
	color: #666A71;
	display: flex;
	align-content: center;
}

.table--head--item,
.table--row--item {
	align-items: center;
}

.table--head, .table--row {
	display: flex;
	flex-direction: row;
}

.table--row--odd { background: rgba(248, 248, 248, 1); }
.table--row--even { background: rgba(243, 243, 244, 1); }

.table--row--item {
	font-size: 1em;
	line-height: 1.5em;
	min-height: calc(5em - 1.75em);
	color: #3A4049;
	display: flex;
}

.table--pointer {
	cursor: pointer;
}

th:nth-child(1),
td:nth-child(1) {
	width: 13%;
}

th:nth-child(2),
td:nth-child(2) {
	width: 30%;
}

th:nth-child(3),
td:nth-child(3) {
	width: 23%;
}

th:nth-child(4),
td:nth-child(4) {
	width: 34%;
}
</style>
