<template>
	<div class="input--container">
		<input
			v-if="!isTextarea"
			class="input--field"
			type="text"
			:disabled="disabled"
			:value="value"
		>
		<div
			v-if="isTextarea"
			class="input--field"
			:disabled="disabled"
			v-html="highlightedValue"
		>
		</div>
	</div>
</template>

<script>
import Prism from "prismjs"

export default {
	name: "LUIInput",
	props: {
		isTextarea: Boolean,
		disabled: Boolean,
		value: {
			type: String,
			default: ""
		}
	},
	computed: {
		highlightedValue: function () {
			if (this.value !== null) {
				const highlightedString = Prism.highlight(this.value, Prism.languages.json, "json")
				return highlightedString
			} else {
				return this.value
			}
		}
	},
	mounted() {
		Prism.highlightAll()
	}
}
</script>

<style scoped>
::-webkit-scrollbar {
	width: 0em;
}

.input--container {
	display: flex;
	width: 100%;
	height: 100%;
	margin-bottom: 0.625em;
	flex-grow: 1;
	white-space: pre;
}

.input--container .input--field {
	height: auto;
	resize: none;
	flex-grow: 1;
	overflow: scroll;
	white-space: pre-wrap;
}

.input--container .input--field::-webkit-scrollbar-track {
	background: rgba(15, 22, 33, 0);
	height: 0;
	width: 0;
}

.input--container .input--field {
	padding: 0.625em;
	margin: 0em;
	font-size: 0.6875em;
	font-family: "Fira Sans Mono", monospace;
	font-weight: bold;
	color: rgba(255, 255, 255, 0.8);
	background: rgba(15, 22, 33, 0.3);
	border: 0.0625em solid rgba(15, 22, 33, 0.4);
}
</style>
