<template>
	<div
		class="ltb--container"
		:class="classes"
		@click="clickEvent"
	>
		<div class="ltb--label">{{ label }}</div>
		<div class="ltb--underline"></div>
	</div>
</template>

<script>
export default {
	name: "LUITabButton",
	props: {
		label: {
			type: String,
			default: ""
		},
		active: Boolean,
		clickEvent: {
			type: Function,
			default () {
				return function () {}
			}
		}
	},
	computed: {
		classes: function () {
			return {
				"ltb--container--active": this.active,
				"ltb--container--normal": !this.active,
			}
		}
	}
}
</script>

<style scoped>
	.ltb--container {
		height: 2.375em;
		width: 7.5em;
		border-radius: 0.1875em 0.1875em 0 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		padding: 0.25em;
		cursor: pointer;
	}
	
	.ltb--label {
		font-size: 1em;
		line-height: 1.25em;
		margin-bottom: 0.25em;
	}
	
	.ltb--container--normal .ltb--label {
		font-family: "FiraGo Regular", sans-serif;
		color: rgba(15, 22, 33, 0.6);
	}
	
	.ltb--container .ltb--underline {
		height: 0.1875em;
		width: 6.25em;
		background: rgba(0, 182, 178, 1);
	}

	.ltb--container--normal {
		background: none;
	}
	
	.ltb--container--active {
		background: rgba(255, 255, 255, 1);
	}

	.ltb--container--normal .ltb--underline {
		opacity: 0;
	}

	.ltb--container--active .ltb--underline {
		opacity: 1;
	}
	
	.ltb--container--active .ltb--label {
		font-family: "FiraGo Medium", sans-serif;
		color: rgba(15, 22, 33, 0.8);
	}
</style>
