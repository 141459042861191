<template>
	<div
		class="lo--container"
		:class="{ 'lo--container--background-dark': dark }"
	>
		<div class="spinner--container">
			<svg class="loading--spinner">
				<circle
					cx="1.25em"
					cy="1.25em"
					r="0.75em"
					stroke="rgba(15, 22, 33, 0.8)"
					stroke-width="0.25em"
					fill="none"
				></circle>
			</svg>
			<svg>
				<circle
					cx="1.25em"
					cy="1.25em"
					r="0.75em"
					stroke="rgba(15, 22, 33, 0.4)"
					stroke-width="0.25em"
					fill="none"
				></circle>
			</svg>
		</div>
	</div>
</template>

<script>
export default {
	name: "LoadingOverlay",
	props: {
		dark: Boolean
	}
}
</script>

<style scoped>
	.lo--container {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(255, 255, 255, 0.8);
	}

	.lo--container--background-dark {
		background: rgba(15, 22, 33, 0.3);
	}

	.lo--container--background-dark .spinner--container svg circle {
		stroke: rgba(255, 255, 255, 0.5);
	}

	.lo--container--background-dark .spinner--container .loading--spinner circle {
		stroke: rgb(255, 255, 255);
	}

	.spinner--container {
		position: relative;
		width: 2.5em;
		height: 2.5em;
	}

	.spinner--container svg {
		position: absolute;
		top: 0;
		left: 0;
		height: 2.5em;
		width: 2.5em;
	}

	.loading--spinner {
		clip-path: polygon(100% 0, 0 0, 50% 50%);
		animation: rotate 0.8s ease-in-out infinite;
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		5% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
