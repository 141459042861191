const categories = [
	"ATM",
	"Bank",
	"Car Repair",
	"Coffee Shop",
	"Fast Food",
	"Gallery",
	"Grocery",
	"Museum",
	"Restaurant",
	"Shopping Mall"
]

export default categories
