<template>
	<div class="slider--container">
		<div class="slider--label">
			{{ label }}
		</div>
		<div class="slider--controls">
			<div class="slider--range">
				<input
					v-model="currentValue"
					type="range"
					name="radius"
					min="0"
					:max="max"
					step="1"
				>
			</div>
			<div class="slider--value">
				<input
					v-model="currentValue"
					type="number"
				>
				<span class="slider--value--label">{{ unit }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LUISlider",
	props: {
		label: {
			type: String,
			default: ""
		},
		valueType: {
			type: String,
			default: ""
		},
		max: {
			type: String,
			default: ""
		},
		unit: {
			type: String,
			default: ""
		}
	},
	computed: {
		currentValue: {
			get () {
				return this.$store.state[this.valueType]
			},
			set (value) {
				this.$store.commit(`${this.valueType}Update`, parseInt(value))
			}
		}
	}
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.slider--label {
	font-family: "FiraGo Medium", sans-serif;
	font-size: 0.8125em;
	color: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
}

.slider--controls {
	display: flex;
	flex-grow: 1;
}

.slider--value {
	width: 3.75em;
	height: 2.5em;
	padding: 0.625em;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.slider--value input {
	font-family: "FiraGo Regular", sans-serif;
	font-size: 0.8125em;
	border-color: rgba(14, 22, 33, 0.7);
	border-style: solid;
	background: rgba(255, 255, 255, 0.1);
	color: rgba(255, 255, 255, 0.8);
	padding: 0.625em;
	width: calc(100% - 1.25em);
	height: calc(100% - 1.25em);
}

.slider--value--label {
	font-family: "FiraGo Regular", sans-serif;
	font-size: 0.8125em;
	position: absolute;
	right: 1.4375em;
	top: 1.7em;
	color: rgba(255, 255, 255, 0.8);
}

.slider--range {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
}

input[type=range] {
  -webkit-appearance: none;
	appearance: none;
	width: auto;
	background: transparent;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
	width: 1.375rem;
	height: 1.375rem;
	background: white;
  cursor: pointer;
	box-shadow: 0.0625em 0.0625em 0.1875em rgba(0, 0, 0, 0.5);
	border-radius: 1.375rem;
	margin-top: -0.375rem;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
	height: 0.625rem;
  cursor: pointer;
	background: rgba(125, 129, 135, 1);
	border-radius: 0.0625rem;
}
</style>
