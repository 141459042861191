<template>
	<div class="bdv--container">
		<div class="bdv--top">
			<div class="bdv--top--headline">{{ dataset.headline }}</div>
			<div
				v-for="text in dataset.text"
				:key="text"
				class="bdv--top--text"
			>{{ text }}</div>
		</div>
		<div class="bdv--bottom">
			<div class="bdv--navigationbar">
				<LUITabButton
					label="Table"
					:active="isTable"
					:click-event="showTable"
				></LUITabButton>
				<LUITabButton
					label="Map"
					:active="!isTable"
					:click-event="showMap"
				></LUITabButton>
			</div>
			<div class="bdv--content">
				<LUIUserTable
					v-show="isTable"
					:show="show"
					:labels="tableHeadlines"
					:content-keys="tableContentKeys"
					:entries="getCurrentFilteredCollection"
					:clickable="getIsClickable"
					flag-type="buildings"
				>
				</LUIUserTable>
				<MapView
					v-show="!isTable"
					type="buildings"
					:is-table="isTable"
					:show="show"
					:single-device="getSingleDeviceBuildings"
					:center="getCurrentCollectionCenterLocation"
					:radius="getCurrentSearchRadius"
					:draw-radius="getMapDrawRadius"
					:marker-collection="getMapMarkerCollection"
					:trigger-update="getRandom"
					:disable-eventhandler="isEventHandlerDisabled"
				>
				</MapView>
				<div
					v-show="getApplicationIsLoading"
					class="pdv--loading"
				>
					<LoadingOverlay></LoadingOverlay>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LUIUserTable from "../components/LUIUserTable"
import LUITabButton from "../components/LUITabButton"
import MapView from "../components/MapView"
import LoadingOverlay from "../components/LoadingOverlay"
import { mapGetters } from "vuex"

export default {
	name: "BuildingsDataView",
	components: {
		LUIUserTable,
		LUITabButton,
		MapView,
		LoadingOverlay
	},
	data () {
		return {
			dataset: {
				headline: "Your Input",
				text: [
					"Your Geo-coordindates",
					"Dataset: Lower Manhattan, New York",
					"Date: August - December 2018"
				]
			},
			isTable: true,
			tableHeadlines: [
				{
					key: "id",
					value: "Entity ID"
				},
				{
					key: "buildingscount",
					value: "Buildings"
				},
				{
					key: "lat",
					value: "Latitude"
				},
				{
					key: "long",
					value: "Longitude"
				}
			],
			tableContentKeys: [
				"id",
				"buildingscount",
				"lat",
				"long"
			],
			isClickable: false,
			currentPois: [],
			currentDevices: [],
			show: Math.random()
		}
	},
	computed: {
		...mapGetters([
			"isEventHandlerDisabled",
			"getRandom",
			"getDeviceDataCollection",
			"getCurrentFilteredCollection",
			"getSingleDeviceBuildingsArray",
			"getIsClickable",
			"getCurrentCollectionCenterLocation",
			"getCurrentSearchRadius",
			"getMapMarkerCollection",
			"getApplicationIsLoading",
			"getMapDrawRadius",
			"getSingleDeviceBuildings"
		])
	},
	mounted () {
		this.$store.commit("setIsClickableFalse")
		this.$store.commit("setApiExplorerRequestState")
		this.$store.commit("hideOverlay")

		//TODO Dirty hack to init here map lib correctly to show up
		const showIt = this.showMap
		const hideIt = this.showTable

		this.$nextTick(function () {
			setTimeout(function () {
				showIt()
			}, 0)
			setTimeout(function () {
				hideIt()
			}, 100)
		})
	},
	methods: {
		showTable: function () {
			this.isTable = true
			this.show = Math.random()
		},
		showMap: function () {
			this.isTable = false
			this.show = Math.random()
		}
	},
	beforeRouteUpdate (to, from, next) {
		this.$store.commit("hideOverlay")
		next()
	}
}
</script>

<style scoped>
.bdv--container {
	padding: 1.5em;
	width: calc(100% - 1.5em * 2);
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.bdv--top {
	margin-bottom: 3em;
}

.bdv--top--headline {
	font-family: "FiraGO Bold", sans-serif;
	font-size: 2em;
	color: rgba(15, 22, 33, 0.8);
}

.bdv--top--text {
	font-family: "FiraGO Medium", sans-serif;
	font-size: 0.8125em;
	color: rgba(15, 22, 33, 0.6);
}

.bdv--bottom {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 50vh; /* it is growing to max viewable size because of flex-grow: 1*/
}

.bdv--navigationbar {
	display: flex;
	flex-direction: row;
	padding-left: 0.75em;
}

.bdv--content {
	display: flex;
	flex-grow: 1;
	flex-basis: 100%;
	padding: 1em;
	background: rgba(255, 255, 255, 1);
	position: relative;
}

.pdv--loading {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
</style>
