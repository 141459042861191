<template>
	<div class="flag--container">
		<div class="flag--label">{{ label }}</div>
	</div>
</template>

<script>
export default {
	name: "LUIFlag",
	props: {
		label: {
			type: String,
			default: ""
		}
	}
}
</script>

<style scoped>
	.flag--container {
		background: rgba(0, 182, 178, 1);
		height: 1.25em;
		padding: 0 0.625em;
		border-radius: 0.1875em;
	}

	.flag--label {
		font-family: "FiraGO Medium", sans-serif;
		font-size: 0.8125em;
		line-height: 1.5em;
		text-transform: uppercase;
		color: rgba(255, 255, 255, 1);
	}
</style>
