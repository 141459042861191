<template>
	<div class="sbdv--container">
		<LUITable
			v-if="isTable"
			headline="Buildings"
			:labels="tableHeadlines"
			:device="getSingleDeviceBuildings"
			:entries="getSingleDeviceBuildingsArray"
			:content-keys="tableContentKeys"
			:clickable="false"
		>
		</LUITable>
	</div>
</template>

<script>
import LUITable from "../components/LUITable"
import { mapGetters } from "vuex"

export default {
	name: "SingleBuildingDataView",
	components: {
		LUITable
	},
	data () {
		return {
			isTable: true
		}
	},
	computed: {
		tableHeadlines: function () {
			if (this.getSingleDeviceBuildings.buildingscount === 1) {
				return [
					"Name",
					"Height",
					"Zip Code",
					"Country"
				]
			} else {
				return [
					"Distance",
					"Name",
					"Zip Code",
					"Country"
				]
			}
		},
		tableContentKeys: function () {
			if (this.getSingleDeviceBuildings.buildingscount === 1) {
				return [
					"name",
					"height",
					"postal_code",
					"isocc"
				]
			} else {
				return [
					"calc_distance",
					"name",
					"postal_code",
					"isocc"
				]
			}
		},
		...mapGetters([
			"getSingleDeviceBuildings",
			"getSingleDeviceBuildingsArray",
			"getDeviceDataCollection"
		])
	},
	beforeUpdate () {
		const firstElementOfTable = document.querySelector("#table--device--meta")
		firstElementOfTable.scrollIntoView()
	},
	methods: {
		showTable: function () {
			this.isTable = true
		},
		showMap: function () {
			this.isTable = false
		}
	}
}
</script>

<style scoped>
</style>
