<template>
	<div class="fp--container">
		<LUIFlag
			v-show="count > 0"
			:label="`${count} POIs added`"
		></LUIFlag>
	</div>
</template>

<script>
import LUIFlag from "./LUIFlag"
export default {
	name: "FlagPlaces",
	components: {
		LUIFlag
	},
	props: {
		count: {
			type: Number,
			default: 0
		}
	}
}
</script>
