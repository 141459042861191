<template>
	<div class="sc--buildings--container">
		<div class="sc--buildings--label">API Layer</div>
		<div class="sc--buildings--headline">HERE Building Footprint</div>
		<div class="sc--buildings--description">Query the Buildings API layer to understand if your mobile location signals are inside of buildings and in which building exactly.</div>
		<div class="sc--buildings--slider">
			<LUISlider
				label="Search Radius"
				value-type="searchRadius"
				unit="m"
			>
			</LUISlider>
		</div>
		<div class="sc--buildings--button">
			<LUIButton
				:click-event="emulateBuildingsQuery"
				membrane="context"
				button-label="Send Query"
				:disabled="getApplicationIsLoading"
				size="medium"
				:primary="true"
				:is-loading="getApplicationIsLoading"
			></LUIButton>
		</div>
	</div>
</template>

<script>
import LUIButton from "./LUIButton"
import LUISlider from "./LUISlider"
import { mapGetters } from "vuex"

export default {
	name: "SidebarContentBuildings",
	components: {
		LUIButton,
		LUISlider
	},
	data () {
		return {
			isLoading: false
		}
	},
	computed: {
		...mapGetters(["getApplicationIsLoading"])
	},
	methods: {
		emulateBuildingsQuery: function () {
			clearTimeout(emulatedLoadingTimeout)

			this.$router.push("/app/buildings")
			this.$store.commit("setApplicationIsLoading", true)
			this.$store.commit("setBuildingResponseCollection")
			this.$store.commit("setIsClickableFalse")
			this.$store.commit("setEventHandlerEnabled")
			const emulatedLoadingTimeout = setTimeout(() => {
				this.$store.commit("hideOverlay")
				this.$store.commit("setDeviceDataCollectionFiltered")
				this.$store.commit("setBuildingRequestCollection")
				this.$store.commit("setIsClickableTrue")
				this.$store.commit("setApiExplorerResponseState")
				this.$store.commit("setMapMarkerCollectionBuildings")
				//this.$store.commit("setMapZoom", 14)
				this.$store.commit("setApplicationIsLoading", false)
			}, 500)
		}
	}
}
</script>

<style scoped>
.sc--buildings--container {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 1.25em;
}

.sc--buildings--label {
	color: rgba(255, 255, 255, 0.6);
	text-decoration: underline;
	margin-bottom: 1.25em;
	font-size: 0.8125em;
}

.sc--buildings--headline {
	color: rgba(255, 255, 255, 1);
	margin-bottom: 0.625em;
	font-size: 2em;
	font-weight: bold;
}

.sc--buildings--subheadline {
	color: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
	font-family: "FiraGo Medium", sans-serif;
	font-size: 0.8125em;
	margin-bottom: 1.75em;
}

.sc--buildings--description {
	color: rgba(255, 255, 255, 0.8);
	font-size: 1em;
	line-height: 1.625em;
	margin-bottom: 4em;
}

.sc--buildings--slider {
	margin-bottom: 1.825em;
}

.sc--buildings--button {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.sc--buildings--selection {
	margin-bottom: 1.25em;
}
</style>
