<template>
	<div class="sc--import--container">
		<div class="sc--import--label">API Explorer</div>
		<div class="sc--import--query-input">
			<LUIInput
				:is-textarea="true"
				:disabled="true"
				:value="valueUrl"
			></LUIInput>
		</div>
		<div class="sc--import--buttons">
			<LUIButton
				:click-event="toggleRequest"
				membrane="context"
				button-label="Request"
				:disabled="false"
				size="medium"
				:primary="getApiExplorerRequestState"
			></LUIButton>
			<LUIButton
				:click-event="toggleResponse"
				membrane="context"
				button-label="Response"
				:disabled="false"
				size="medium"
				:primary="getApiExplorerResponseState"
			></LUIButton>
		</div>
		<div class="sc--import--query-output">
			<LUIInput
				:is-textarea="true"
				:disabled="true"
				:value="valueBody"
			></LUIInput>
		</div>
		<div
			v-show="getApplicationIsLoading"
			class="sc--loading"
		>
			<LoadingOverlay :dark="true"></LoadingOverlay>
		</div>
	</div>
</template>

<script>
import LUIInput from "./LUIInput"
import LUIButton from "./LUIButton"
import LoadingOverlay from "../components/LoadingOverlay"
import { mapGetters } from "vuex"

export default {
	name: "SidebarContentBuildingsQuery",
	components: {
		LUIInput,
		LUIButton,
		LoadingOverlay
	},
	computed: {
		...mapGetters([
			"getDeviceDataCollection",
			"getDeviceRequestCollection",
			"getDeviceResponseCollection",
			"getApiExplorerRequestState",
			"getApiExplorerResponseState",
			"getCurrentSearchRadius",
			"getCurrentPoiCount",
			"getCurrentCollectionCenterLocation",
			"getApplicationIsLoading"
		]),
		valueUrl: function () {
			const radius = this.getCurrentSearchRadius
			const poicount = this.getCurrentPoiCount

			return `https://{{host}}/layers/{{place_layer_id}}/anchor?result_radius=${radius}&result_limit=${poicount}`
		},
		valueBody: function () {
			if (this.getApiExplorerRequestState) {
				return JSON.stringify(this.getDeviceRequestCollection, undefined, 2)
			} else {
				if (this.getDeviceResponseCollection === "") {
					return null
				} else {
					return JSON.stringify(this.getDeviceResponseCollection, undefined, 2)
				}
			}
		}
	},
	methods: {
		toggleRequest: function () {
			this.$store.commit("setApiExplorerRequestState")
		},
		toggleResponse: function () {
			this.$store.commit("setApiExplorerResponseState")
		}
	}
}
</script>

<style scoped>
.divider {
	background: rgba(255, 255, 255, 0.15);
	width: 100%;
	height: 0.0625em;
	margin: 0.625em 0;
}

.sc--import--container {
	display: flex;
	flex-direction: column;
	padding: 1.25em;
	height: 100%;
	position: relative;
}

.sc--import--label {
	text-decoration: underline;
	color: rgba(255, 255, 255, 0.6);
	margin-bottom: 1.25em;
	font-size: 0.8125em;
}

.sc--import--headline {
	color: rgba(255, 255, 255, 1);
	margin-bottom: 0.625em;
	font-size: 2em;
	font-weight: bold;
}

.sc--import--subheadline {
	color: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
	font-family: "FiraGo Medium", sans-serif;
	font-size: 0.8125em;
	margin-bottom: 1.75em;
}

.sc--import--description {
	color: rgba(255, 255, 255, 0.8);
	font-size: 1em;
	line-height: 1.625em;
	margin-bottom: 4em;
}

.sc--import--buttons {
	display: flex;
	margin-bottom: 2.5em;
}

.sc--import--button {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.sc--import--query-input {
	display: flex;
	flex-direction: column;
	min-height: 5em;
}

.sc--import--query-output {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.sc--loading {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
</style>
